import { render } from "./detail_s.vue?vue&type=template&id=728cf29a&scoped=true"
import script from "./detail_s.vue?vue&type=script&lang=js"
export * from "./detail_s.vue?vue&type=script&lang=js"

import "./detail_s.vue?vue&type=style&index=0&id=728cf29a&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-728cf29a"
/* hot reload */
if (module.hot) {
  script.__hmrId = "728cf29a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('728cf29a', script)) {
    api.reload('728cf29a', script)
  }
  
  module.hot.accept("./detail_s.vue?vue&type=template&id=728cf29a&scoped=true", () => {
    api.rerender('728cf29a', render)
  })

}

script.__file = "src/views/community/detail/detail_s.vue"

export default script