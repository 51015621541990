<template>

  <div class="wrapper">
    <!--         知识付费落地页-->

    <headBack>
      <template v-slot:title>
        <div class='title'>
          详情
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>

    <div class="detail">
        <div class="head">

             <div class="left">
                  <div class="name">{{detail.NAME}}</div>
                 <div class="sub">
                   <span v-for="(k,v) in label">{{k}}</span>
                 </div>
               <div class="xs">
                 <span>￥{{detail.PRICE}}</span>
                 <span class="sum"> 限时特价￥<span>{{detail.PROMOTION_PRICE}}</span></span>
               </div>
             </div>
            <div class="right">
              <img :src="detail.HEAD_PIC_URL" alt="">
              <p class="cont">已有{{detail.SALE_NUM}}人报名</p>
            </div>
        </div>

        <div class="time">
             <div class="names">{{detail.DESCRIPTION}}</div>
             <div>
                 <div>距截止报名</div>
                 <div>5天23:34:14</div>
             </div>
        </div>


    </div>
    <div class="img_box">
      <img v-for="(item,index) in info_url" :src="item" alt="">
    </div>
    <div class="bottom_fix">
      <div class="btn btn1" @click="showKf">
        在线咨询
      </div>
      <div class="btn btn2" @click="toPay">
        立即购买
      </div>
    </div>
  </div>
  <van-popup v-model:show="show" :style="{ backgroundColor: 'rgba(0,0,0,0)' }" position="bottom">
    <div class="kf_box">
<!--      <div class="zx" @click.stop="toZx">在线客服</div>-->
      <div class="dh" @click.stop="toPhone">客服电话</div>
      <div class="qx" @click.stop="toQx">取消</div>
    </div>
  </van-popup>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import {reactive,ref} from 'vue';
import homeApi from '@axios/home';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  name: "detail",
  setup () {
    const show = ref(false);
    let router = useRouter()
    const store = useStore()
    const route = useRoute()


    const active_color = ref('')
    const detail = ref('');
    const label = ref('');
    const info_url = ref('');
    const getDetail = () => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProductInfo",
        productId: route.query.productId,
        userId: store.state.uid,
        newversion: '2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res) => {

        console.log(res);
         detail.value = res.data;
         label.value = res.data.LABEL.split('、');
         info_url.value = res.data.INFO_URL.split(',');
        // active_color.value = res.data.TABBAR_COLOR
        // list.value = res.data;
        setWxShareDate(`医护到家：${res.data.NAME}`,res.data.HEAD_PIC_URL)//分享
      })
    }
    getDetail()
    const toPay = () => {
      router.push({
        name:'communityOrder',
        query:{
          productId:route.query.productId
        }
      })

    }
    const toPhone = ()=> {
      window.location.href = 'tel://4008006996';

    }
    const isWx = () =>{
      let ua = window.navigator.userAgent.toLowerCase();
      return (ua.match(/MicroMessenger/i) == 'micromessenger') ? true : false;
    }


    const toZx =()=>  {

        if(isWx()){//微信浏览器
          //小程序环境判断
          wx.miniProgram.getEnv((res)=>{
            if(res.miniprogram){
              uni.navigateTo({
                url:'/pages/home/detail/kfWebView/kfWebView'
              })
              return;
            }else{//微信公众号
              window.location.href = 'http://im.7x24cc.com/?accountId=N000000007036&chatId=qyjk-6f8fc5f0-9b49-11e6-bf1e-03bffe68584e';

            }
          })
        }else{//非微信浏览器
          window.location.href = 'http://im.7x24cc.com/?accountId=N000000007036&chatId=qyjk-6f8fc5f0-9b49-11e6-bf1e-03bffe68584e';
        }


    }
    const toQx = () => {
      show.value =false;
    }
    const showKf = () => {
      console.log('111')
      show.value =true;
    }
    return {
       label,
       info_url,
       detail,
       toPay,
        toPhone,
        toZx,
        toQx,
        showKf,
      show
    }
  }
}
</script>

<style scoped lang="scss">
 .wrapper{
   background-color: rgb(120,177,255);
 }
 .detail{
    box-sizing: border-box;
   padding:30px;
 }
 .time{
   box-sizing: border-box;
   background-color: #FFFFFF;
   padding:30px;
   border-radius: 16px;
   display: flex;
   justify-content: space-between;
   margin: 30px 0;
   font-size: 28px;
   .names{
     color:rgb(120,177,255);
   }
 }
 .head{
   box-sizing: border-box;
   background-color: #FFFFFF;
   padding:30px;
   border-radius: 16px;
   display: flex;
   justify-content: space-between;
   .left{

      .name{
        color:rgb(120,177,255);
        font-size: 32px;
      }
     .sub{
       display: flex;
       margin:16px 0 16px 0px;
       span{
         display: block;
         margin-right: 16px;
         border-radius: 6px;
         font-size: 26px;
         border:1px solid #F7DAB7;
         padding:4px 8px;
         color:#E5B379;
         background: #FDE1E0;
       }
     }
     .xs{
       font-size: 28px;
       margin-top: 4px;
       color:#333;
       .sum{
         color:#FF6C00;
         span{
           font-size: 38px;
         }
       }
     }
   }
   .right{
     img{
       width: 260px;
     }
     p{
       margin: 0;
       font-size: 26px;
       text-align: center;
       color:#999999;
     }
   }
 }

 .img_box{
   padding-bottom: 100px;
   img{
     width: 100%;
     display: block;
   }

 }
.bottom_fix{

  position: fixed;
  left:0;
  bottom:0;
  z-index: 100;
  height:120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 30px;
  box-shadow:0px -15px 20px -12px rgb(120,177,255);
  box-sizing: border-box;
  padding:0 30px;
   background-color: rgb(120,177,255);
  .btn{
    color:#fff;
    background-color: #999999;
    padding: 16px 70px;
    border-radius: 40px;
  }
  .btn1{
    background-color: #5454DE;
  }
  .btn2{
     background-color: #FF6C00;
  }
}

 .kf_box{
   color:#00C291;
   box-sizing: border-box;
   width: 92%;

   margin: 30px auto;
   border-radius: 12px;
   font-size: 32px;

   div{
     box-sizing: border-box;
     background-color: #FFFFFF;
     line-height: 78px;
     text-align: center;
     border-bottom: 1px solid #f0f0f0;
   }
   .qx{
     margin-top: 18px;
     border-radius: 6px;
   }
   .zx{
     border-radius: 6px 6px 0 0;
   }
   .dh{
     border-radius:  6px ;
   }
 }
</style>
